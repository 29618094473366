body.kitty a {
  transition: opacity var(--hover-time);
  opacity: 1;
}
body.kitty a:hover {
  opacity: var(--hover-opacity);
}

body.kitty .kitty-hover-zoom {
  transition: translate var(--hover-time);
  transform: scale(100%, 100%);
}
body.kitty .kitty-hover-zoom:hover {
  transform: scale(var(--hover-zoom-scale), var(--hover-zoom-scale));
}

body.kitty .kitty-hover-overlay:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity var(--hover-time);
  opacity: 0;
  display: none;
  background-color: var(--hover-overlay-color);
}
body.kitty .kitty-hover-overlay:before:hover {
  opacity: 1;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  body.kitty {
    transition: all 0.01s !important;
    animation: none !important;
  }
}