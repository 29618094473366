body {
  margin: auto;
}
body .menu-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  transition: top 1s;
  height: 64px;
  background: white;
  top: 0px;
  z-index: 10001;
}
body .menu-container .menus-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 42px;
  margin-right: 42px;
}
body .menu-container .menus-content .current {
  text-decoration: underline;
  text-decoration-color: #00ffd1;
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
}
body .header-logo {
  width: 110px;
  height: auto;
}
body .kitty-menu.menu-top-of-page {
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
body .kitty-menu.menu-top-of-page a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--text-color);
  text-decoration: none;
}
body .kitty-menu.menu-top-of-page a:hover {
  opacity: 0.6;
}
body .kitty-menu.menu-hamburger {
  display: none;
}
body .kitty-menu.menu-hamburger .button {
  display: inline-block;
  height: 3em;
  position: relative;
  width: 3.4em;
  z-index: 10020;
  right: -18px;
  top: 8px;
}
body .kitty-menu.menu-hamburger .popup {
  padding-top: 150px;
  padding-bottom: 150px;
  background: var(--page-background-color);
}
body .kitty-menu.menu-hamburger .popup .row {
  text-align: center;
}
body .kitty-menu.menu-hamburger .popup .row a {
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: none;
  font-family: var(--p1-font);
}
body .kitty-menu.menu-hamburger.show .popup {
  width: 100%;
  height: 100%;
  position: fixed;
}
body .mainMenu a:link {
  text-decoration: none;
  color: black;
}
body .mainMenu a:visited {
  text-decoration: none;
  color: black;
}
body > main > aside {
  top: clamp(1em, 4vmax, 3em);
}
body footer {
  padding: clamp(3em, 4vmax, 5em);
  background: #f5f5f5;
  min-height: 100%;
}
body footer a,
body footer p {
  text-decoration: none;
  color: var(--h2-color);
  font-size: var(--text-size);
  font-family: var(--p1-font);
}
body footer .text-container {
  display: flex;
}
body footer .text-container .first-inner-container {
  flex: 1;
}
body footer .text-container .second-inner-container {
  align-items: end;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
body footer .social-media-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
body footer .logo {
  margin-bottom: 20px;
}
body .contact-button {
  background-color: #00ffd1;
  padding: 6px 35px;
}
body header {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1;
  height: auto;
}

@media only screen and (min-width: 1001px) {
  body header {
    position: fixed;
    top: 0px;
    z-index: 1;
  }
}
@media only screen and (max-width: 1000px) {
  body .kitty-menu.menu-top-of-page a {
    display: none;
  }
  body .kitty-menu.menu-top-of-page a.contact-button {
    display: inline-block;
    max-height: 36px;
    padding: 6px 12px;
    z-index: 10011;
    font-size: 0.938rem;
  }
  body .header-logo {
    z-index: 10012;
    position: relative;
  }
  body .menu-container {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  body .menu-container .menus-content {
    padding-top: 10px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  body .kitty-menu.menu-hamburger {
    display: inline-block;
  }
  body footer .logo {
    display: none;
  }
  body footer .footer-container {
    display: block;
    text-align: center;
  }
  body footer .footer-container .text-container {
    display: block;
  }
  body footer .footer-container .text-container .second-inner-container {
    display: block;
  }
  body footer .footer-container .social-media-container {
    display: block;
  }
}