body.kitty {
  /* Kitty page */
  --page-background-color: #ffffff;
  /* Sidebar positions on desktop and pad */
  --sidebar-left-width: clamp(15ch, 15vmax, 40ch);
  --sidebar-right-width: clamp(15ch, 15vmax, 40ch);
  /* Page (sidebar) margins on desktop and pad */
  --sidebar-outer-margin: clamp(2ch, 2vmax, 4ch);
  --sidebar-inner-margin: clamp(1ch, 1vmax, 2ch);
  /* Page margins on mobile */
  --mobile-side-margin: clamp(2ch, 2vmax, 4ch);
  /* Sectons vertical margins */
  --section-vertical-margin: 3rem;
  --section-fullwidth-vertical-margin: 3rem;
  /* Sectons internal gap, used between columns in modules */
  --section-internal-gap: 2em;
  /* Kitty common elements */
  --link-text-color: #0000aa;
  --link-text-hover-color: #0000ff;
  --menu-inline-background: #444444;
  --menu-inline-color: #ffffff;
  --meny-inline-divider-color: #ffffff;
  --menu-hamburger-background: #eeeeee;
  --meny-hamburger-divider-color: #000000;
  --menu-hamburger-color: #444444;
  --menu-hamburger-gap: 1em;
  --menu-hamburger-width: 25vw;
  --backdrop-background: #eeeeeeaa;
  /* Standard html elements */
  --body-text-size: 1rem; /* Only use rem for this one, to respect browser config and accessibility */
  --text-font: "", sans-serif;
  --text-size: 1rem;
  --text-color: #000000;
  --h1-font: "Messina", sans-serif;
  --h1-size: 3rem;
  --h1-color: #ffffff;
  --h2-font: "Messina", sans-serif;
  --h2-size: 2.375rem;
  --h2-color: #000000;
  --h3-font: "Messina", sans-serif;
  --h3-size: 1.625rem;
  --h3-color: #000000;
  --p1-size: 1.375rem;
  --p1-font: "Manrope", sans-serif;
  --p1-color: #000000;
  --p2-size: 1.25rem;
  --p2-font: "Manrope", sans-serif;
  --p2-color: #000000;
  --primary-color-storey: #00ffd1;
  --mobile-h1-color: #000000;
  --mobile-h1-size: 1.75rem;
  --mobile-p1-color: #000000;
  --mobile-p1-size: 1rem;
  --button-size-text: 1.125rem;
  /* Kitty effects and animations */
  --hover-time: 0.2s;
  --hover-overlay-color: #aaaaff55;
  --hover-zoom-scale: 110%;
  --hover-opacity: 0.8;
  /* Kitty accessibility */
  --accessibility-keyboard-outline-color: #770999;
  --accessibility-keyboard-outline-width: 4px;
  --accessibility-mouse-outline-color: #ffffff;
  --accessibility-mouse-outline-width: 1px;
}