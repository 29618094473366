/*
 * .kitty-button
 *
 * .kitty-link
 *    --link-text-color
 *    --link-text-hover-color
 *
 */
body.kitty .kitty-button {
  white-space: nowrap;
  overflow: hidden;
  line-height: 2em;
}

body.kitty .kitty-image {
  width: 100%;
  aspect-ratio: 4/3;
  background-size: contain;
  overflow: hidden;
}

body.kitty .kitty-link {
  color: var(--link-text-color);
  transform: color var(--hover-time);
}
body.kitty .kitty-link:hover {
  color: var(--link-text-hover-color);
}

body.kitty .kitty-richtext a {
  color: var(--link-text-color);
}

body.kitty .kitty-top-menu {
  line-height: 2em;
}
body.kitty .kitty-top-menu li {
  display: flex;
  flex-wrap: none;
  overflow: hidden;
}
body.kitty .kitty-top-menu li ul {
  flex: 1;
  list-style-type: none;
  margin-right: 2em;
}