* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-wrap: break-word;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}