@font-face {
  font-family: "Manrope";
  src: url("../wp-content/themes/storey-interior/assets/fonts/Manrope-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../wp-content/themes/storey-interior/assets/fonts/Manrope-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../wp-content/themes/storey-interior/assets/fonts/Manrope-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../wp-content/themes/storey-interior/assets/fonts/Manrope-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Messina";
  src: url("../wp-content/themes/storey-interior/assets/fonts/MessinaSansMonoWeb-Black.woff");
  font-style: normal;
}