/*
 * Kitty assumes a site with this structure.
 * Margins are set with css variables.
 * IF you don't want this structure, omit class="kitty" and ignore the kitty files.
 *
 * <body class="kitty">
 *   <main>
   *   <section="my-hero">
 *       my-hero content, full width
 *     </section>
 *     <section="my-section">
 *       <margins>
 *         my-section content, with propper margins
 *       </margins>
 *     </section>
 *   </main>
 * </body>
 */
body.kitty {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--page-background-color);
  font-size: var(--body-text-size);
}
body.kitty > header {
  display: block;
}
body.kitty > middle {
  display: block;
  position: relative;
}
body.kitty > middle > main {
  flex: 1;
}
body.kitty > middle > main > section {
  display: block;
  margin-bottom: var(--section-fullwidth-vertical-margin);
  overflow: hidden;
}
body.kitty > middle > main > section > margins {
  display: block;
  margin: 0 calc(var(--sidebar-right-width) + var(--sidebar-outer-margin) + var(--sidebar-inner-margin)) calc(var(--section-vertical-margin) - var(--section-fullwidth-vertical-margin)) calc(var(--sidebar-left-width) + var(--sidebar-outer-margin) + var(--sidebar-inner-margin));
  overflow: hidden;
}
@media (max-width: 499.99px) {
  body.kitty > middle > main > section > margins {
    margin: 0 var(--mobile-side-margin) calc(var(--section-vertical-margin) - var(--section-fullwidth-vertical-margin)) var(--mobile-side-margin);
  }
}
body.kitty > middle > main > section .no-margin {
  margin-bottom: 0;
}
body.kitty > middle > main > section .no-margin margins {
  margin-bottom: 0;
}
body.kitty > middle > aside.left {
  position: absolute;
  left: var(--sidebar-outer-margin);
  display: block;
  width: var(--sidebar-left-width);
}
@media (max-width: 499.99px) {
  body.kitty > middle > aside.left {
    position: static;
    left: 0;
    width: 100%;
  }
}
body.kitty > middle > aside.right {
  position: absolute;
  right: var(--sidebar-outer-margin);
  display: block;
  width: var(--sidebar-right-width);
}
@media (max-width: 499.99px) {
  body.kitty > middle > aside.right {
    position: static;
    right: 0;
    width: 100%;
  }
}
body.kitty > footer {
  display: block;
}