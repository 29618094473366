/*
 * :focus is WHEN CLICKING THE MOUSE
 */
*:focus {
  outline: var(--accessibility-mouse-outline-width) solid var(--accessibility-mouse-outline-color);
}

/*
 * :focus-visible is WHEN USING THE KEYBOARD
 */
*:focus-visible {
  outline: var(--accessibility-keyboard-outline-width) solid var(--accessibility-keyboard-outline-color);
}