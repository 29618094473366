.kitty-menu.menu-hamburger {
  display: inline-block;
}
.kitty-menu.menu-hamburger .button {
  display: inline-block;
  width: 4em;
  height: 4em;
  position: relative;
  z-index: 10020;
}
.kitty-menu.menu-hamburger .popup {
  background: var(--menu-hamburger-background);
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 10010 !important;
  padding: calc(2 * var(--menu-hamburger-gap));
  right: 0;
  top: 0;
  width: var(--menu-hamburger-width);
}
.kitty-menu.menu-hamburger .popup .row {
  display: flex;
}
.kitty-menu.menu-hamburger .popup a {
  padding: var(--menu-hamburger-gap);
  flex: 1;
  display: block;
  color: var(--menu-hamburger-color);
}
.kitty-menu.menu-hamburger .popup .divider {
  flex: 1;
  border-bottom: 1px solid var(--meny-hamburger-divider-color);
  margin: var(--menu-hamburger-gap) 0;
}
.kitty-menu.menu-hamburger .popup .text {
  padding: var(--menu-hamburger-gap);
  flex: 1;
}
.kitty-menu.menu-hamburger .popup .submenu-toggle {
  border: 0;
  aspect-ratio: 1/1;
  background-image: url(/assets/kitty/arrow-down.svg);
  background-size: 40%;
  background-position: center;
  background-repeat: no-repeat;
}
.kitty-menu.menu-hamburger .popup .submenu-toggle.show {
  background-image: url(/assets/kitty/arrow-up.svg);
}
.kitty-menu.menu-hamburger .popup .submenu-toggle:hover {
  filter: brightness(120%);
}
.kitty-menu.menu-hamburger .popup .submenu {
  display: none;
  flex-direction: column;
  margin-left: calc(2 * var(--menu-hamburger-gap));
  overflow: hidden;
  padding: 4px;
  margin-top: 0;
}
.kitty-menu.menu-hamburger .popup .submenu.show {
  display: flex;
}
.kitty-menu.menu-hamburger.show .popup {
  display: flex;
}